<template>
  <Dialog
    :open="true"
    @close="props.onClose"
  >
    <div class="fixed inset-0 flex items-center justify-center p-4 z-20 bg-zinc-700/90">
      <DialogPanel class="border border-white w-[800px] max-h-[90vh] flex flex-col bg-black">
        <DialogTitle class="border-b border-white p-3 text-yellow-300">
          {{ text('ui.settings_title') }}
        </DialogTitle>

        <!-- sound section -->
        <div class="w-100 p-4 flex flex-col items-center">
          <!-- music -->
          <div class="w-[60%]">
            <div class="text-sm text-neutral-400">{{ text('ui.music') }}</div>
            <!-- <Slider defaultValue={this.state.musicVolume} onChange={this.onMusicChange}/> -->
            <VueSimpleRangeSlider
              class="-mt-8 w-100"
              :min="0"
              :max="100"
              v-model="state.music_volume"
              @update:model-value="onMusicChange"
            />
          </div>
           <!-- sfx -->
          <div class="mt-2 w-[60%]">
            <div class="text-sm text-neutral-400">{{ text('ui.sound_effects') }}</div>
            <!-- <Slider defaultValue={this.state.musicVolume} onChange={this.onMusicChange}/> -->
            <VueSimpleRangeSlider
              class="-mt-8 w-100"
              :min="0"
              :max="100"
              v-model="state.sfx_volume"
              @update:model-value="onSFXChange"
            />
          </div>
        </div>
        <hr/>
        <!-- credits section -->
        <div class="p-4 text-center">
          <!-- credits title -->
          <div class="mb-6 text-yellow-300">{{ text('ui.credits') }}</div>
          <!-- credits container -->
          <div class="flex flex-wrap justify-center gap-x-24 gap-y-6">
            <!-- lead: Rob -->
            <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.lead_developer') }}</div>
              <div class="text-blue-500">Robert Lombardo</div>
              <a class="-mt-2 w-[170px] cursor-pointer" href="https://wakefield-studios.com" target="_blank">
                <img :src="`${Config.DOM_IMG_PATH}/wfs_logo_white.png`" />
              </a>
            </div>
            <!-- visuals: Otto -->
            <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.graphic_designer') }}</div>
              <div class="text-blue-500">Otto Chin</div>
            </div>
            <!-- composer: Mattias -->
            <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.composer') }}</div>
              <div class="text-blue-500">Mattias Holmgren</div>
              <a class="mt-2 w-[100px] cursor-pointer" href="https://www.morningdewmedia.com/" target="_blank">
                <img :src="`${Config.DOM_IMG_PATH}/morningdew_logo.png`" />
              </a>
            </div>
            <!-- Bizdev: Dan -->
            <!-- <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.business_development') }}</div>
              <div class="text-blue-500">Daniel Knott</div>
            </div> -->
            <!-- QA Lead: Kele -->
            <!-- <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.qa_lead') }}</div>
              <div class="text-blue-500">Dániel Kelemen</div>
            </div> -->
            <!-- Project Manager: Dylan -->
            <!-- <div class="flex flex-col items-center">
              <div class="font-bold">{{ text('ui.project_manager') }}</div>
              <div class="text-blue-500">Dylan Quast</div>
            </div> -->
          </div>
        </div>
        <hr/>
        <div class="p-4 text-center">
          <a class="text-red-800 cursor-pointer" @click="onLogOutClick">
            Log Out
          </a>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import firebase from 'firebase/compat/app';
import { reactive } from 'vue';
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/css';
import Audio from '~/Audio';
import { Config } from '~/constants';
import { SettingsActions  } from '~/flux/actions';
import { SettingsStore } from '~/flux/stores';
import text from '~/text';
import Tools from '~/Tools';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const state = reactive({
  music_volume: SettingsStore.getAll().musicVolume * 100,
  sfx_volume: SettingsStore.getAll().sfxVolume * 100
});

function onMusicChange(val) {
  Audio.setMusicVolume(val/100);
  saveSettings();
};

function onSFXChange(val) {
  Audio.setSFXVolume(val/100);
  saveSettings();
};

const saveSettings = Tools.debounce(() => {
  SettingsActions.saveSettings({
    musicVolume: Audio.getMusicVolume(),
    sfxVolume: Audio.getSFXVolume()
  });
}, 100);

function onLogOutClick() {
    firebase.auth().signOut();
    window.location.reload();
  }
</script>

<style>
.simple-range-slider-popover {
  display: none;
}

.simple-range-slider-popover-arrow {
  display: none;
}
</style>
