import { EventEmitter } from 'events';
import firebase from 'firebase/compat/app';
import Economy from 'dt-common/constants/Economy';
import Audio from '~/Audio';
import Colors from '~/constants/Colors';
import { ChatActions } from '~/flux/actions';
import {
  BattleDebriefDispatcher,
  EditHeroDispatcher,
  GameItemDispatcher,
  LoadoutDispatcher,
  PlayerDispatcher,
} from '~/flux/dispatchers';
import { awaitSocket, registerDispatchHandlers } from '~/Tools';

let playerId;
let _socket;

// the stuff we serve:
let gameState = null;
let num_unlocked_heroes = 0;
let heroUnlockCost = 999999;
let selectedItem = null;

const GameStateStore = Object.assign({}, EventEmitter.prototype, {
  GOT_GAME_STATE: 'GOT_GAME_STATE',
  HERO_UNLOCK_INITIATED: 'HERO_UNLOCK_INITIATED',
  HERO_UNLOCKED: 'HERO_UNLOCKED',
  HERO_CYCLED: 'HERO_CYCLED',
  ITEM_SELECTED: 'ITEM_SELECTED',
  SHOW_MIRACLE_DYE_DIALOG: 'SHOW_MIRACLE_DYE_DIALOG',
  EQUIPMENT_CHANGE: 'EQUIPMENT_CHANGE',
  XP_BOOST_USED: 'XP_BOOST_USED',
  TEAM_CHANGE: 'TEAM_CHANGE',
  NO_INVENTORY_SPACE: 'NO_INVENTORY_SPACE',
  ITEM_REWARD_CLAIMED: 'ITEM_REWARD_CLAIMED',
  HERO_LEVEL_UP: 'HERO_LEVEL_UP',
  LOADOUT_UPDATED: 'LOADOUT_UPDATED',

  getAll() {
    return {
      gameState,
      num_unlocked_heroes,
      heroUnlockCost,
      selectedItem,
    };
  },

  getHero({ base = false, handle, game_mode, game_submode }) {
    return (
      base
        ? gameState.hero_roster[handle]
        : gameState.loadouts[game_mode][game_submode].find(hero => hero.handle === handle)
    );
  },
});
export default GameStateStore;

PlayerDispatcher.register(registerDispatchHandlers({
  [PlayerDispatcher.PLAYER_LOGGED_IN]: onPlayerLoggedIn,
}));
EditHeroDispatcher.register(registerDispatchHandlers({
  [EditHeroDispatcher.HERO_UNLOCK_CONFIRM]: requestHeroUnlock,
  [EditHeroDispatcher.MOVE_ITEM]: requestMoveItem,
  [EditHeroDispatcher.USE_XP_BOOST]: requestUseXPBoost,
}));
GameItemDispatcher.register(registerDispatchHandlers({
  [GameItemDispatcher.ACTIVATE_MIRACLE_DYE]: activateMiracleDye,
  [GameItemDispatcher.DYE_ITEM]: requestDyeItem,
  [GameItemDispatcher.EQUIP_BAG_TO_GLOBAL_BAG_SLOT]: requestEquipBagToGlobalBagSlot,
  [GameItemDispatcher.EQUIP_BAG_TO_HERO_BAG_SLOT]: requestEquipBagToHeroBagSlot,
  [GameItemDispatcher.EQUIP_ITEM]: requestEquipItem,
  [GameItemDispatcher.GLOBAL_BAG_SLOT_UNLOCK_CONFIRM]: requestUnlockGlobalBagSlot,
  [GameItemDispatcher.HERO_BAG_SLOT_UNLOCK_CONFIRM]: requestUnlockHeroBagSlot,
  [GameItemDispatcher.PLACE_ITEM_IN_GLOBAL_INVENTORY]: requestPlaceItemInGlobalInventory,
  [GameItemDispatcher.PLACE_ITEM_IN_HERO_INVENTORY]: requestPlaceItemInHeroInventory,
  [EditHeroDispatcher.UNEQUIP_ITEM]: requestUnequipItem,
}));
BattleDebriefDispatcher.register(registerDispatchHandlers({
  [BattleDebriefDispatcher.CLAIM_ITEM_REWARD]: claimItemReward,
  [BattleDebriefDispatcher.CLAIM_ALL_REWARDS]: claimAllRewards,
}));
LoadoutDispatcher.register(registerDispatchHandlers({
  [LoadoutDispatcher.SWAP_ENGAGED_HERO]: requestSwapEngagedHero,
}));

awaitSocket(onSocketConnected);
// awaitSocket().then(onSocketConnected);
function onSocketConnected(socket) {
  _socket = socket;
  if (!_socket.has_GameStateStore_listeners) {
    _socket.on('challengeResolved', onChallengeResolved);
    _socket.on('equipment_change', onEquipmentChange);
    _socket.on('hero_build_deleted', onHeroBuildDeleted);
    _socket.on('hero_xp_boost_used', onHeroXPBoostUsed);
    _socket.on('heroUnlocked', onHeroUnlocked);
    _socket.on('illegal_game_state_update', onIllegalGameStateUpdate);
    _socket.on('itemRewardClaimed', onItemRewardClaimed);
    _socket.on('loadout_updated', onLoadoutUpdated);
    _socket.on('new_arena_team_handles', onNewArenaTeamHandles);
    _socket.on('noRoomInInventory', onNoRoomInInventory);
    _socket.on('playerInventory', onPlayerInventory);
    _socket.on('returnedToSurface', onReturnedToSurface);

    _socket.has_GameStateStore_listeners = true;
  }
}

async function onPlayerLoggedIn(action) {
  try {
    const { player } = action;

    playerId = player._id;
    gameState = player.gameState;

    if (process.env.DT_TIER === 'localdev') {
      console.info({ gameState });
    }

    num_unlocked_heroes = calcUnlockedHeroes();
    heroUnlockCost = Economy.HERO_UNLOCK_COST[num_unlocked_heroes];

    GameStateStore.emit(GameStateStore.GOT_GAME_STATE);

    _socket.emit('get_player_inventory', { playerId });

    if (num_unlocked_heroes === 0) {
      firebase.analytics().logEvent('onboard_step:0', {
        name: 'got_new_game_state',
      });
    }
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onPlayerLoggedIn',
      action,
    });
  }
}

function onPlayerInventory(data) {
  try {
    if (data._id !== playerId) {
      throw new Error('playerIventory ID mismatch');
    }

    gameState.inventory = data.inventory;
    GameStateStore.emit(GameStateStore.EQUIPMENT_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onPlayerInventory',
      data,
    });
  }
}

function requestHeroUnlock(action) {
  const heroHandle = action.hero.handle;

  _socket.emit('unlock_hero', {
    playerId,
    heroHandle,
  });

  if (calcUnlockedHeroes() === 0) {
    firebase.analytics().logEvent('onboard_step:1', {
      name: 'sent_first_hero_unlock_req',
      heroHandle,
    });
  }
}

function onHeroUnlocked(data) {
  try {
    const { heroHandle, hero_builds, hero_roster, loadouts } = data;

    gameState.hero_builds = hero_builds;
    gameState.hero_roster = hero_roster;
    if (loadouts) {
      gameState.loadouts = loadouts;
    }

    num_unlocked_heroes = calcUnlockedHeroes();
    heroUnlockCost = Economy.HERO_UNLOCK_COST[num_unlocked_heroes];

    GameStateStore.emit(GameStateStore.HERO_UNLOCKED, heroHandle);

    // fire off some analytics events
    firebase.analytics().logEvent('hero_unlocked', {
      heroHandle,
      total_unlocked: num_unlocked_heroes,
    });
    if (num_unlocked_heroes === 1) {
      firebase.analytics().logEvent('onboard_step:2', {
        name: 'unlocked_first_hero',
        heroHandle,
      });
    }
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onHeroUnlocked',
      data,
    });
  }
}

function requestPlaceItemInGlobalInventory({ game_item, from, to }) {
  _socket.emit('place_item_in_global_inventory', {
    playerId,
    item_id: game_item.uid,
    from,
    to,
  });
}

function requestPlaceItemInHeroInventory({ game_item, from, to }) {
  _socket.emit('place_item_in_hero_inventory', {
    playerId,
    item_id: game_item.uid,
    from,
    to,
  });
}

function requestEquipItem({ game_item, from, to }) {
  _socket.emit('equip_item', {
    playerId,
    item_id: game_item.uid,
    from,
    to,
  });
}

function requestUnequipItem({ game_item, from }) {
  _socket.emit('unequip_item', {
    playerId,
    item_id: game_item.uid,
    from,
  });
}

function requestMoveItem(action) {
  _socket.emit('moveItem', {
    playerId,
    itemId: action.item.uid,
    targetPage: action.targetPage,
    targetIndex: action.targetIndex,
  });
}

function requestDyeItem(action) {
  _socket.emit('dye_item', {
    playerId,
    dyeId: action.dyeId,
    item_id: action.itemId,
    from: action.from,
    to: action.to,
    focused_hero_handle: action.focused_hero_handle,
  });
}

function activateMiracleDye(action) {
  _socket.emit('activate_miracle_dye', {
    playerId,
    dyeId: action.dyeId,
    tint: action.tint,
    item_id: action.itemId,
    from: action.from,
    to: action.to,
    focused_hero_handle: action.focused_hero_handle,
  });
}

function requestUseXPBoost(action) {
  _socket.emit('use_hero_xp_boost', {
    playerId,
    potionId: action.potionId,
    heroHandle: action.heroHandle,
  });
}

function onHeroXPBoostUsed(data) {
  try {
    gameState.inventory = data.inventory;

    if (data.hero) {
      gameState.hero_roster[data.hero.handle] = data.hero;
    }

    GameStateStore.emit(GameStateStore.EQUIPMENT_CHANGE);
    GameStateStore.emit(GameStateStore.XP_BOOST_USED);

    Audio.play('level_up');
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onHeroXPBoostUsed',
      data,
    });
  }
}

function onEquipmentChange(data) {
  try {
    const { hero_roster, inventory, hero } = data;

    if (hero_roster) {
      gameState.hero_roster = hero_roster;
    }
    if (inventory) {
      gameState.inventory = inventory;
    }
    if (hero) {
      gameState.hero_roster[data.hero.hero_handle || data.hero.handle] = hero;
    }

    GameStateStore.emit(GameStateStore.EQUIPMENT_CHANGE);
    Audio.play('equipment_storage');
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onEquipmentChange',
      data,
    });
  }
}

function onNewArenaTeamHandles(data) {
  try {
    gameState.competitionData.matchTypes[data.matchType].team = data.team_handles;
    GameStateStore.emit(GameStateStore.TEAM_CHANGE);
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onNewArenaTeamHandles',
      data,
    });
  }
}

function onChallengeResolved(data) {
  try {
    const { playerStateUpdates, tutorial } = data;
    if (tutorial || !playerStateUpdates) {
      return;
    }
    const my_state_update = playerStateUpdates[playerId];
    for (const [handle, hero] of Object.entries(my_state_update?.hero_roster || {})) {
      Object.assign(gameState.hero_roster[handle], hero);
    }
    GameStateStore.emit(GameStateStore.GOT_GAME_STATE);
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onChallengeResolved',
      data,
    });
  }
}

function claimItemReward(action) {
  const { battle_id, itemId } = action;

  _socket.emit('claim_item_rewards', {
    playerId,
    battle_id,
    itemIds: [itemId],
  });
}

function claimAllRewards(action) {
  _socket.emit('claim_all_rewards', {
    playerId,
    battle_id: action.battle_id,
  });
}

function onItemRewardClaimed(data) {
  GameStateStore.emit(GameStateStore.ITEM_REWARD_CLAIMED, data.claimedItemId);
}

function onReturnedToSurface(data) {
  try {
    // handle xp gains
    for (let prop in gameState.hero_roster) {  
      const newHero = data.hero_roster[prop];

      if (gameState.hero_roster[prop].level < newHero.level) {
        GameStateStore.emit(GameStateStore.HERO_LEVEL_UP, {
          heroHandle: prop,
          newLevel: newHero.level,
        });

        // Audio.play('level_up');

        if ((newHero.level%5) === 0 || newHero.level >= 20) {
          ChatActions.gameNotification('hero_level_up', { heroHandle: prop,level: newHero.level });
        }
      }
    }

    gameState.hero_roster = data.hero_roster;
    gameState.inventory = data.inventory;

    GameStateStore.emit(GameStateStore.GOT_GAME_STATE);
  } catch (err) {
    logError(err, {
      module: 'GameStateStore',
      func: 'onReturnedToSurface',
      data,
    });
  }
}

function calcUnlockedHeroes() {
  return Object.values(gameState.hero_roster).filter(h => h.level > 0).length;
}

function onNoRoomInInventory() {
  GameStateStore.emit(GameStateStore.NO_INVENTORY_SPACE);
}

function onIllegalGameStateUpdate(data) {
  $addMessageLogMessage(data.message, Colors.RED);
}

function requestSwapEngagedHero({
  game_mode,
  game_submode,
  hero_to_swap_in,
  hero_to_swap_out,
}) {
  _socket.emit('swap_engaged_hero', {
    game_mode,
    game_submode,
    hero_to_swap_in,
    hero_to_swap_out,
    playerId,
  });
}

function onLoadoutUpdated({ game_mode, game_submode, loadout }) {
  gameState.loadouts[game_mode][game_submode] = loadout;
  GameStateStore.emit(GameStateStore.LOADOUT_UPDATED, { game_mode, game_submode, loadout });
}

function requestEquipBagToGlobalBagSlot(action) {
  _socket.emit('equip_bag_to_global_bag_slot', {
    playerId,
    ...action,
  });
}

function requestEquipBagToHeroBagSlot(action) {
  _socket.emit('equip_bag_to_hero_bag_slot', {
    playerId,
    ...action,
  });
}

function requestUnlockGlobalBagSlot() {
  _socket.emit('unlock_global_bag_slot', {
    playerId
  });
}

function requestUnlockHeroBagSlot(action) {
  _socket.emit('unlock_hero_bag_slot', {
    playerId,
    ...action,
  });
}

function onHeroBuildDeleted(data) {
  if (data.loadouts) {
    gameState.loadouts = data.loadouts;
  }
}
