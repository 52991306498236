import Game from 'dt-common/constants/Game';
import heroes from './heroes';
const { GAME_MODES, GAME_SUBMODES } = Game;

const ui = {
  // initialization messages
  initializing_kong_api: 'Initializing Kongregate API...',
  connecting_to_kong: 'Connecting to Kongregate...',
  connected_to_kong: 'Connected to Kongregate!',
  unable_to_connect_to_kong: 'Unable to connect to Kongregate. Try refreshing!',
  getting_fb_status: 'Getting Facebook login status...',
  not_logged_into_fb: 'Not logged in with Facebook. Doing device authorization...',
  unable_to_connect_to_fb: 'Unable to connect to Facebook. Doing device authorization...',
  unable_to_init_fb_API: 'Unable to init Facebook API. Doing device authorization...',
  connected_to_fb: 'Connected to Facebook!',
  found_browser_id_cookie: 'Found existing browser ID...',
  no_browser_id_cookie: 'No browser ID found - generating new account...',
  authenticated: 'Authenticated!',
  ad_blocker_msg: {
    a: 'Ad blocker detected!',
    b: 'This can mess up the game.',
    c: 'Dungeon Team will NEVER serve ads. Ew!',
    d: 'Please whitelist dungeonteam.com in your ad blocker settings :)',
  },

  safari_warning: 'We\'re sorry, Dungeon Team is not available via Safari. Please try again in another browser, such as Chrome.',

  kicked_off_msg: 'You have been disconnected from the game. Redirecting to forum...',

  // Welcome Modal
  welcome_title: 'Welcome to...',
  PLAY: 'PLAY',
  announcements: 'Announcements',
  devlog: 'Devlog',
  featured_video: 'Featured Video',
  patch_notes: 'Patch Notes',
  how_to_submit_video: 'Submit my video!',
  help_lost_game_data: 'HELP! I lost my game data!',
  show_changelog: 'show changelog',
  hide_changelog: 'hide',

  welcome_msg: 'Welcome to Dungeon Team!',
  join_forum_msg: 'Join the forum at: http://dungeonteam.com/forum',
  join_discord_msg: 'Join us on Discord at: https://discord.gg/ysgHx3ErwJ',
  chat_connected_msg: 'Connected to global chat.',
  no_log_entries: 'No unread log entries.',
  description: 'Description',
  get_inn_log_entry_description: log_entry => `${ui.game_submodes[log_entry.game_submode]} tier ${log_entry.old_tier} tournament completed.`, 

  // Firebase Modal
  login_with_email: 'Login with email',
  email_label: 'Email Address:',
  password_label: 'Password:',
  log_in: 'Log in',
  reset_password: 'Reset Password',
  create_new_account: 'Create New Account',
  or_use_one_of_these: 'Or use one of these:',
  or_login_with_yandex: 'Or login with Yandex (recommended)',

  // hero builds
  hero_build_name: 'Hero Build Name',
  swap_hero_builds: 'Swap Hero Builds',

  // header
  player_name: 'Player Name',
  what_should_we_call_you: 'What should we call you?',
  player_name_placeholder: 'Enter a name here...',
  settings: 'Settings',
  battle_log: 'Battle Log',
  inn_tourney_log: 'Inn Tournament Log',
  leaderboards: 'Leaderboards',
  get_connected: 'Get connected',
  change_display_name: 'Change your display name',

  // footer
  need_3_heroes: '3 Heroes',
  need_level_5: 'Lv 5 Hero',
  need_5k_gold: '5,000g',
  currently_equipped: 'Currently Equipped',

  // leaderboard
  leaderboard: 'Leaderboard',
  global: 'Global',
  use_location: 'Use Location',
  update_location: 'Update Location',
  name: 'Name',
  rating: 'Rating',
  w_l: 'W-L',
  team: 'Team',

  // Social Modal
  social_modal_title: 'Get connected...',

  price: 'Price',
  pixie_dust: 'Pixie Dust',
  pixie_dust_short: 'PD',
  yandex_currency: 'Yan',
  pay: 'Pay',

  OK: 'OK',
  yes: 'YES',
  no: 'NO',
  delete: 'Delete',
  cancel: 'Cancel',
  this_color: 'This Color. Go.',

  login: 'Login',
  logout: 'Logout',
  login_modal_title: 'Connect your account & play from any device!',
  login_with: 'Login with...',
  facebook_connect_prompt: 'Connect to Facebook!',
  facebook_connect_explanation: [
    'This will allow you to...',
    'Access your game data from any device.',
    'Avoid losing your game data if browser cookies are lost.',
  ],
  connected_to_facebook: 'Connected to Facebook.',

  MENU: 'MENU',
  ARMORY: 'ARMORY',
  ARENA: 'ARENA',
  ARENA_LOBBY: 'ARENA',
  CAVERNS: 'CAVERNS',
  BATTLE_DEBRIEF: 'BATTLE DEBRIEF',
  SHOP: 'SHOP',
  INN: 'INN',
  HERO_BUILDS: 'ARMORY',
  TEMPLE: 'TEMPLE',

  // game modes/submodes
  game_modes: {
    GAME_MODE_pvpAuto: 'Inn',
    GAME_MODE_pvpLive: 'Arena',
    GAME_MODE_caverns: 'Caverns',
  },
  game_submodes: {
    GAME_SUBMODE_pvp1v1: '1v1',
    GAME_SUBMODE_pvp2v2: '2v2',
    GAME_SUBMODE_pvp3v3: '3v3',
    GAME_SUBMODE_pvp5v5: '5v5',
    GAME_SUBMODE_pvp7v7: '7v7',
    GAME_SUBMODE_pvp11v11: '11v11',
    GAME_SUBMODE_caverns1man: '1-man',
    GAME_SUBMODE_caverns2man: '2-man',
    GAME_SUBMODE_caverns3man: '3-man',
    GAME_SUBMODE_caverns5man: '5-man',
    GAME_SUBMODE_caverns7man: '7-man',
    GAME_SUBMODE_caverns11man: '11-man',
  },

  question_proceed: 'Proceed?',

  my_heroes: 'My Heroes',
  summon_a_hero: 'Summon a Hero',

  inventory: 'Inventory',
  use: 'Use',
  equip: 'Equip',
  unequip: 'Unequip',
  move: 'Move',
  select_item_to_dye: 'Select an item to dye.',

  free: 'Free',
  gold: 'Gold',
  gold_brief: 'g',
  purchase_pixie_dust: 'Purchase Pixie Dust',
  get_free_pixie_dust: 'Free Pixie Dust :)',
  get_newsletter: 'Get the newsletter:',
  enter_email: 'enter email',
  enter_password: 'enter password',
  submit_newsletter_btn: 'Submit',
  newsletter_signup_success: 'Success - we\'ll keep you posted!',
  like_us_on_fb: 'Like us on Facebook...',
  follow_us_on_twitter: '...Follow us on Twitter',
  plus_100_pd: '+100 PD!',
  rate_us: 'Help us out with a rating!',

  // edit hero screen
  equipment: {
    full: 'Equipment',
    brief: 'Equip.',
  },
  abilities: {
    full: 'Abilities',
    brief: 'Abil.',
  },
  attributes: {
    full: 'Attributes',
    brief: 'Att.',
  },
  ai_settings: {
    full: 'AI Settings',
    brief: 'AI',
  },
  global_inventory: 'Your Inventory',
  bag_slot_unlock_title: 'Unlock Bag Slot',
  bag_slot_unlock_prompt: unlock_cost => `Unlock a new bag slot for ${unlock_cost} Pixie Dust?`,

  // Edit Attributes
  primary_attributes: 'Primary Attributes',
  secondary_attributes: 'Secondary Attributes',
  reset_attributes: 'Reset Attributes',

  // hero unlock modal
  hero_unlock_title: 'A new hero awaits battle...',
  hero_unlock_question: {
    p1: 'Unlock the ',
    /*hero name*/
    p2: ' for ',
    /*unlock cost*/
    p3: ' Pixie Dust?',
  },

  // ability upgrade modal
  ability_upgrade_title: {
    p1: 'The ',
    /*hero name*/
    p2: '\'s power grows...',
  },
  ability_upgrade_question: {
    p1: 'Spend 1 Ability Point to ugprade ',
    /*ability name*/
    p2: '?',
  },

  locked: 'Locked',
  reset_abilities: 'Reset Abilities',
  tabula_rasa: 'Tabula rasa...',
  abilities_reset_question: {
    p1: 'Reset the ',
    /*hero name*/
    p2: '\'s abilities for ',
    /*reset cost*/
    p3: ' Pixie Dust?',
  },
  attributes_reset_question: {
    p1: 'Reset the ',
    /*hero name*/
    p2: '\'s attributes for ',
    /*reset cost*/
    p3: ' Pixie Dust?',
  },

  // Edit AI Settings
  role_priorities: 'Role Priorities',
  ai_roles: {
    tank: 'Tank',
    dps_close: 'DPS Close',
    dps_ranged: 'DPS Ranged',
    interference: 'Interference',
    support: 'Support',
  },

  // Inn
  rank: 'Rank',
  new_rank: 'New rank',
  peanuts: 'Peanuts',
  peanuts_per_second: 'Peanuts drop rate',
  peanuts_burgled: 'Peanuts burgled',
  time_left: 'Time Left',
  burgle: 'Burgle',
  attack_loadout: 'Attack Formation',
  done: 'Done',
  peanuts_to_steal: 'Peanuts to steal',
  inn_tier_titles: {
    1: 'Tier 1 - Noobs',
    2: 'Tier 2 - Quasi-Noobs',
    3: 'Tier 3 - Non-Noobs',
    4: 'Tier 4 - Players',
    5: 'Tier 5 - Challengers',
    6: 'Tier 6 - Veterans',
    7: 'Tier 7 - Experts',
    8: 'Tier 8 - Elites',
    9: 'Tier 9 - Masters',
    10: 'Tier 10 - Grandmasters',
  },
  challenges_left: 'Challenges left',
  inn_rank_1_congrats: 'Congrats! You hold the top rank in the current tourney.',
  everyone_embattled: 'All available opponents are embattled! Stand by ...',
  inn_logs_title: 'Inn Tournament Logs',

  // Arena Lobby
  match_type: 'Match Type',
  match_types: {
    arena1v1: '1v1',
    arena2v2: '2v2',
    arena3v3: '3v3',
    arena5v5: '5v5',
    arena7v7: '7v7',
  },
  fight: 'Fight!',
  fight_AI: 'Fight AI',
  fight_live: 'Fight Live',
  victory: 'VICTORY!',
  defeat: 'DEFEAT',
  rating_change: 'Rating Change',
  new_rating: 'New Rating',
  leaderboard_rank: 'Global LB Rank',
  rewards: 'Rewards',
  claim: 'Claim',
  claim_all: 'Claim All',
  battle_log: 'Battle Log',
  ended_at: 'Ended At',
  opponent: 'Opponent',
  opponents: 'Opponents',
  result: 'Result',
  online_status: 'Status',
  online: 'Online',
  offline: 'Offline',
  in_battle: 'In Battle',
  won: 'Won',
  lost: 'Lost',
  avg_opponent: 'Avg. Opponent',
  live_challenge: 'Live challenge!',
  quick_matchup: 'Quick Matchup',
  online_matchup: 'Live Matchup',
  challenge_by_name: 'Challenge by Name',
  challenge_player: 'Challenge Player',
  whom_do_you_challenge: 'Whom do you challenge?',
  challenge_issued: 'Challenge issued! Please wait...',
  roster: 'Roster',
  go_back: 'Go back',
  change: 'Change',
  defense: 'Defense',
  edit: 'Edit',
  engage: 'Engage',
  rename: 'Rename',
  rename_hero_modal_title: (hero_handle) => `What shall we call this ${heroes[hero_handle].name} build?`,

  // Shop
  shop: {
    out_of_stock_msg: 'Sorry, I\'m all out of stock right now.\n\nI\'ll buy your junk tho.',
  },
  items_to_sell: 'Items to Sell',
  buy: 'Buy',
  sell: 'Sell',
  keep: 'Keep',
  sell_all: 'Sell All',
  haggle: 'Haggle',
  lets_deal: () => {
    const candidates = [
      'Let\'s make a deal.',
      'What have you got for us today?',
      'Good day out there?',
      'This looks like some nice stuff, heh.',
      'Back again I see...',
    ];
    return candidates[Math.floor(Math.random() * candidates.length)];
  },

  // Altar
  altar_summary: {
    pt_1: 'Sacrifice ',
    pt_2: ' to the god of ',
    pt_3: ', and ask for a ',
  },
  gods: {
    chaos: 'ChAoS1',
    balance: 'balance',
    order: 'Order',
  },
  weapon_dagger: 'Dagger',
  weapon_staff: 'Staff',
  weapon_sword: 'Sword',
  weapon_hammer: 'Hammer',
  boots_light: 'Light Boots',
  torsoe_light: 'Light Torso',
  helmet_light: 'Light Helm',
  boots_med: 'Med Boots',
  torsoe_med: 'Med Torso',
  helmet_med: 'Med Helm',
  boots_heavy: 'Heavy Boots',
  torsoe_heavy: 'Heavy Torso',
  helmet_heavy: 'Heavy Helm',
  off_hand_book: 'Book',
  off_hand_bow: 'Bow',
  off_hand_shield: 'Shield',
  off_hand_trinket: 'Trinket',
  ring_ring: 'Ring',
  prayer_slot_unlock_title: 'May the gods smile on you...',
  prayer_slot_unlock_question: {
    p1: 'Unlock a new prayer slot for',
    // (amount)
    p2: 'Pixie Dust?',
  },
  no_prayer_candles: 'You have no prayer candles!',
  devote_a_candle: 'Devote a prayer candle...',
  whom_shall_we_pray_for: 'Whom shall we pray for?',
  endeared_you_to_god: 'has endeared you to the god of',

  // message log
  connected_to_server: 'Connected to server!',
  disconnected_from_server: 'Disconnected from server...',
  insufficient_pixie_dust: 'Not enough Pixie Dust!',
  no_inventory_space: 'No space in your inventory!',
  unlock_a_hero_first: 'Unlock a hero first!',
  no_heroes_in_squad: 'Add a hero to your squad first!',
  username_taken: 'That username has been taken!',
  invalid_username: 'Invalid username.',
  invalid_emote_command: 'Invalid emote command',
  invalid_gender_input: [
    'Invalid gender input.',
    'FORMAT: /gender subj/obj/possessive',
    '(max for each is 7 chars)',
    '',
    'EXAMPLE: /gender she/her/her',
  ],
  unable_to_get_location: 'Unable to get your location.',
  on_pd_credit: 'You have been credited',
  no_player_by_that_name: 'No player by that name.',
  is_offline: 'is not online.',
  is_in_battle: 'is in a battle.',
  enemies_in_play: 'There are still enemies in the room!',
  leveled_up: 'is now level',
  not_enough_gold: 'Not enough gold!',
  equip_some_abilities_first: 'Equip some abilities first!',
  battle_debrief_error: 'Error getting battle debrief.',
  health_check_failed: 'Lost battle connection; recovering...',
  battle_connection_recovered: 'Battle connection recovered!',
  engine_recovery_failed: 'Failed to recover battle engine. Match aborted.',
  warnings: {
    hero_equipment_unassignment_warning: ({ item_name, hero_name }) => `Removing this ${item_name} from the ${hero_name}'s inventory will unequip it from the following loadouts:`,
    no_assign_equipment_from_global: 'You must move the item to hero inventory before equipping to a loadout.',
    no_unequip_item_to_global: 'Move from hero inventory.',
    warning: 'Warning',
  },

  // options menu
  settings_title: 'Settings',
  music: 'Music',
  sound_effects: 'Sound Effects',
  save: 'Save',
  credits: 'Credits',
  lead_developer: 'Lead Developer',
  graphic_designer: 'Visual Designer',
  composer: 'Composer',
  qa_lead: 'QA Lead',
  business_development: 'Business Development',
  project_manager: 'Project Manager',

  // chat
  players_online: 'Players Online',
  send: 'Send',
  chat_text_color_title: 'Chat Text Color',
  choose_chat_text_color: 'Choose your chat text display color... it will look like this:',
  chat_text_color_example: 'Hi, everybody!',
  text_color_change_price_warning: 'It will cost 1,000 Pixie Dust to make this change...',
  chat_color_change_success: 'Success! This is your new chat message color :)',

  // miracle dye dialog
  miracle_dye_dialog_title: 'Activating Miracle Dye...',
  what_color_dye: 'What color dye would you like?',

  // make gold modal
  make_gold: 'Make Gold',
  abracadabra: 'Abracadabra!',

  // caverns
  caverns_squad: 'Caverns Squad',
  caverns_welcome: 'Your heroes stand before a nondescript crevace in the living rock. It is the entrance to the Caverns of Chaos...',
  deepest_level: 'Deepest Level',
  descend: 'Descend',
  descend_caverns_at_1: 'Enter at lv. 1',
  descend_caverns_at_portal_level: (level) => `Enter at Ω level (${level})`,
  while_elsewhere: 'While you were elsewhere...',
  fighting_around_caverns: 'Defending the mouth of the caverns, your heroes have gained...',
  enter_at_level: 'Enter at Level',
  return_to_armory: 'Return to Armory',
  surface: 'Return to Surface',
  returned_to_surface: 'Returned to Surface!',
  descent_has_yielded: 'Your heroes\' descent into the Caverns has yielded...',
  gold_gains: 'Gold Gains',
  level_up: 'Level up!',
  depth: 'Depth',
  portal_level: 'Portal level',
};
export default ui;
