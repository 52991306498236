export default {
  // standard
  melee_attack: {
    name: 'Melee Attack',
    blurb: 'A standard physical attack.',
    calc: {
      apCost: '45 - De/4',
      damage: '50 + St*2 + De + Le',
    },
  },
  move: {
    name: 'Move',
    blurb: 'Walk to an adjacent tile.',
    calc: {
      apCost: '20',
    },
  },

  // Alchemy
  burning_hands: {
    name: 'Burning Hands',
    blurb:
      "Flames spout from the caster's palms, scorching the target's flesh.",
    calc: {
      apCost: '40 - Level',
      mpCost: '5 + Level*4',
      damage: '65 + Level*( Le/5 + Ma/7 + De/10 )',
    },
  },
  fumigate: {
    name: 'Fumigate',
    blurb: 'Releases a cloud of putrid smoke, poisoning all nearby units.',
    calc: {
      mpCost: '40 + Level*4',
      potency: '10 + Level*( Le/6 + Ma/10 )',
    },
  },
  ice_nine: {
    name: 'Ice Nine',
    blurb:
      'Tosses a vial of a volatile liquid - when it shatters, a chemical reaction leaps from Unit to Unit causing a Slowed Condition.',
    calc: {
      mpCost: '20 + Level*2',
      duration: '2 + Level*( Le/80 )',
    },
  },
  lightning: {
    name: 'Lightning',
    blurb:
      'Zaps the target with an electric shock, potentially jumping to other victims - damage is halved on each jump. Lightning ignores armor.',
    calc: {
      mpCost: '50 + Level*4',
      damage: '100 + Level*( Le/7 + Ma/7 )',
    },
  },
  molotov_cocktail: {
    name: 'Molotov Cocktail',
    blurb:
      'Hurls a flaming bottle at the target. Anyone near the explosion will be injured (damage is reduced by distance from the explosion).',
    calc: {
      range: '3 + Level/2',
      apCost: '70 - Level*2',
      mpCost: '20 + Level*4',
      damage: '40 + Level*( Le/2 + De/6 )',
    },
  },
  poison_weapon: {
    name: 'Poison Weapon',
    blurb: 'Until charges run out, weapon attacks will poison their victims.',
    calc: {
      apCost: '50 - Level',
      mpCost: '5 + Level*4',
      potency: '20 + Level*( Le/15 )',
      charges: 'Level + De/4',
    },
  },
  road_to_nowhere: {
    name: 'Road to Nowhere',
    blurb:
      'Tosses a vial of a psycho-active chemical. When it shatters, the fumes cause terrifying hallucinations - the victim flees.',
    calc: {
      mpCost: ' 20 + Level',
      duration: '1 + Level*( Le/100 + Pe/150 )',
    },
  },

  // Armor Use
  armor_spikes: {
    name: 'Armor Spikes',
    blurb:
      'PASSIVE: When this unit takes melee damage, a portion will be returned to the aggressor.',
    calc: {
      potency: '3% per Level',
    },
  },
  bash: {
    name: 'Bash',
    blurb:
      "Throws a heavy shoulder into an adjacent enemy's body, damaging and stunning it.",
    calc: {
      apCost: '50 - Level*2',
      damage: 'Level*( St/6 + To/10 )',
      duration: '1 + Level*( St/200 + De/300 )',
    },
  },
  layers_of_protection: {
    name: 'Layers of Protection',
    blurb: 'PASSIVE: Increases Armor Class',
    calc: {
      potency: 'Level * 1.5',
    },
  },
  missile_defense: {
    name: 'Missile Defense',
    blurb: 'PASSIVE: extra chance to block missile attacks.',
    calc: {
      potency: '5% per Level',
    },
  },
  reflect_magic: {
    name: 'Reflect Magic',
    blurb:
      'PASSIVE: Chance to reflect harmful magic attacks back to the caster.',
    calc: {
      potency: '2% per level',
    },
  },
  shield_block: {
    name: 'Shield Block',
    blurb: 'For a short time, most physical attacks will be stopped.',
    calc: {
      apCost: '35 - Level',
      duration: '0.5 + Level*( St/250 )',
    },
  },
  suture: {
    name: 'Suture',
    blurb: 'Stops bleeding, and restores a few Hit Points.',
    calc: {
      apCost: '65 - Level*2',
      potency: '20 + Level*( Le/8 + De/12 )',
      healing: '10 + Level*( Le/8 + Ma/10 )',
    },
  },

  // Bow Use
  arrow_shot: {
    name: 'Arrow Shot',
    blurb: 'A normal bow shot.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '50 - Level*2',
      damage: '65 + Level*( Pe/8 + De/10 + Le/12 )',
    },
  },
  blunt_arrow: {
    name: 'Blunt Arrow',
    blurb: 'Stuns the target for a short time.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '50 - Level*2',
      damage: 'Level*( Pe/6 + De/10 )',
      duration: '0.5 + Level*( Pe/250 + De/350 )',
    },
  },
  chain_pull: {
    name: 'Chain Pull',
    blurb:
      'Fires an arrow with a chain attached. The target can then be yanked forward.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '70 - Level*2',
      damage: 'Level*( Pe/6 + De/10 )',
      potency: '1 + Level*( St/80 )',
    },
  },
  leg_shot: {
    name: 'Leg Shot',
    blurb: 'Damages the target and applies a Slowed condition.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '50 - Level*2',
      damage: 'Level*( Pe/6 + De/10 )',
      duration: '1 + Level*( Pe/150 + De/250 )',
    },
  },
  multi_shot: {
    name: 'Multi-Shot',
    blurb:
      'Fires arrows simultaneously at the target as well as a number of its nearby allies.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '70 - Level/6',
      charges: '2 + Level/2',
      damage: '50 + Level*( Pe/6 + De/8 + Le/12 )',
    },
  },
  range: {
    name: 'Range',
    blurb:
      'PASSIVE: Each level increases the range of all bow attacks by 1. (Note that bow attacks cannot be used on adjacent targets.)',
    calc: {
      potency: 'Level * 1',
    },
  },
  razor_shot: {
    name: 'Razor Shot',
    blurb:
      'Fires a broad-tipped arrow meant to graze the target, causing damage and bleeding.',
    calc: {
      range: '4 + Range Ability Level',
      apCost: '50 - Level*2',
      damage: 'Level*( De/10 )',
      potency: '15 + Level*( Pe/40 + De/40 )',
    },
  },

  // Conjuration
  mana_node: {
    name: 'Mana Node',
    blurb: 'PASSIVE: bonuses to Mana as well as Magical Gravity.',
    calc: {
      maBonus: 'Level * 2%',
      mgBonus: 'Level / 3 ',
    },
  },
  summon_dwarf: {
    name: 'Summon Dwarf',
    blurb: 'Summons a Dwarven defender, whose attributes scale with Learning.',
    calc: {
      mpCost: 'Level*100;',
      potency: 'Level/2',
      duration: '40 + Level*2',
    },
  },
  summon_elf: {
    name: 'Summon Elf',
    blurb: 'Summons an Elvish archer, whose attributes scale with Learning.',
    calc: {
      mpCost: 'Level*100;',
      potency: 'Level/2',
      duration: '40 + Level*2',
    },
  },
  summon_faerie: {
    name: 'Summon Faerie',
    blurb: 'Summons a healing Faerie, whose attributes scale with Learning.',
    calc: {
      mpCost: 'Level*100;',
      potency: 'Level/2',
      duration: '40 + Level*2',
    },
  },
  summon_option: {
    name: 'Summon Option',
    blurb:
      'An Option orbits target ally for a while, damaging a nearby enemy every 3 seconds. It can absorb one crowd-control condition.',
    calc: {
      mpCost: '10 + Level*3',
      damage: '65 + Level*( Le/10 + Ma/10  )',
      charges: '3 + Level',
    },
  },
  teleport: {
    name: 'Teleport',
    blurb:
      'Move instantly to the target location - some HP are lost while traveling through time.',
    calc: {
      range: '2 + Level/2',
      hpCost: '12% of max - 1% per level',
      mpCost: '15 * Distnace',
    },
  },
  void_spike: {
    name: 'Void Spike',
    blurb:
      'Sharply disrupts gravity at a certain location, causing damage to any physical matter there.',
    calc: {
      range: '4 + Level/2',
      apCost: '35 - Level',
      mpCost: '5 + Level*4',
      damage: '65 + Level*( Ma/8 + Le/10 + Pe/12 )',
    },
  },

  // Illusion
  angel_mask: {
    name: 'Angel Mask',
    blurb: 'PASSIVE: nearby allies are inspired, gaining bonus Willpower.',
    calc: {
      potency: '2 +  Level',
    },
  },
  blurred_form: {
    name: 'Blur',
    blurb:
      'PASSIVE: Increased Chance-to-Dodge, as well as Physical and Mental Resilience.',
    calc: {
      potency: '1% per Level',
    },
  },
  devil_mask: {
    name: 'Devil Mask',
    blurb: 'Scares away all nearby enemies.',
    calc: {
      mpCost: '30 + Level*3',
      duration: '0.5 + Level*( Ma/250 + Le/350 )',
      effectRange: '1 + Level/5',
    },
  },
  faux_self: {
    name: 'Poppets!',
    blurb:
      "Creates 2 illusions in the image of the caster. Each has 10% of the caster's attributes per level, but causes no actual harm.",
    calc: {
      mpCost: '40 + ( Level*5 )',
      potency: 'Level/2',
      duration: '50 + Level*3',
    },
  },
  infect_mind: {
    name: 'Infect Mind',
    blurb:
      "The victim's mind is overpowered with agonizing illusions - hemorrhaging occurs.",
    calc: {
      range: '2 + Level',
      mpCost: '20 + Level*2',
      potency: '15 + Level*( Le/40 + Ma/40 )',
    },
  },
  psyche_out: {
    name: 'Psyche Out',
    blurb:
      'Losing their senses, the target and some of its nearby allies will become enraged against random units.',
    calc: {
      range: '3 + Level/3',
      mpCost: '20 + Level*2',
      duration: '0.5 + Level*( Wi/250 + Pe/350 )',
    },
  },
  sleight_of_hand: {
    name: 'Sleight of Hand',
    blurb: 'Mesmerizes the victim, causing him to lose some Magic Power.',
    calc: {
      range: '2 + Level',
      apCost: '40 - Level*2',
      mpCost: '20 + Level*2',
      potency: '100 + Level*( Ma/2 + De/4 )',
    },
  },

  // Melee Combat
  armor_break: {
    name: 'Armor Break',
    blurb:
      "The target's Armor Class is reduced - effect can stack until the target's total Armor Class is zero.",
    calc: {
      apCost: '45 - Level*2',
      potency: '15 + Level*( St/7 + De/10 )',
    },
  },
  mortal_strike: {
    name: 'Mortal Strike',
    blurb:
      'The target must be slowed or stunned, but AP cost is refunded on a killing blow.',
    calc: {
      apCost: '90 - Level',
      damage: '120 + Level*( St/2 + De/3 + Wi/4 )',
    },
  },
  roundhouse: {
    name: 'Roundhouse',
    blurb:
      'Damages all adjacent units, and those at a distance of 2 tiles take half damage.',
    calc: {
      apCost: '70 - Level',
      damage: '60 + Level*( De/3 + St/4 )',
    },
  },
  sky_hammer: {
    name: 'Sky Hammer',
    blurb:
      'Leap high into the air and thunder back to the ground, knocking back all adjacent units.',
    calc: {
      apCost: '80 - Level',
      damage: '65 + Level*( St/8 + De/10 )',
      potency: '1 + Level/3',
    },
  },
  slam: {
    name: 'Slam',
    blurb: 'Damages the target and knocks it back.',
    calc: {
      apCost: '45 - Level*2',
      damage: 'Level*( St/6 + De/10 )',
      potency: '2 + Level*( St/80 )',
    },
  },
  slash: {
    name: 'Slash',
    blurb: 'Causes damage and bleeding to the target.',
    calc: {
      apCost: '65 - Level*2',
      damage: 'Level*( St/8 )',
      potency: '20 + Level*( De/24 + Le/50 )',
    },
  },
  taunt: {
    name: 'Taunt',
    blurb: 'Enrages the target, and a number of its adjacent allies.',
    calc: {
      apCost: '35 - Level',
      mpCost: '5 + Level',
      duration: '1 + Level*( Wi/200 + Pe/250 )',
    },
  },

  // Movement
  adrenaline: {
    name: 'Adrenaline',
    blurb: 'Converts Magic Power to Action Points.',
    calc: {
      mpCost: '75 - Level',
      potency: '25 + Level*( St/12 )',
    },
  },
  freedom: {
    name: 'Freedom',
    blurb: 'PASSIVE: Increases Physical Resilience.',
    calc: {
      potency: 'Level*3%',
    },
  },
  leap: {
    name: 'Leap',
    blurb: 'No faith required (though it never hurts).',
    calc: {
      range: '2 + Level/2',
      apCost: 'Distance*( MaxAP/MaxRange )',
    },
  },
  preparation: {
    name: 'Preparation',
    blurb: 'PASSIVE: Bonus to Maximum Action Points.',
    calc: {
      potency: 'Level*2',
    },
  },
  quickness: {
    name: 'Quickness',
    blurb: 'PASSIVE: Bonus to Speed.',
    calc: {
      potency: 'Level / 3',
    },
  },
  stance_dancing: {
    name: 'Stance Dancing',
    blurb:
      'Defensive Stance reduces damage both incoming (-3% per level) and outgoing (+3% per level). Offensive Stance increases damage dealt (100% + 3% per level) and received (150% - 3% per level)',
    calc: {
      apCost: '70',
      potency: '3% per level',
    },
  },
  tussle: {
    name: 'Tussle',
    blurb: 'Throws the victim, dealing some damage and switching places.',
    calc: {
      apCost: '80 - Level*3 - De/4',
      damage: '30 + Level*(St/6 + De/8 + Le/12',
    },
  },

  // Spirit
  bless: {
    name: 'Bless',
    blurb:
      'The target gains some Hit Points each second for the duration, as well as reduced ability costs (2% per Level).',
    calc: {
      mpCost: '20 + Level',
      healing: '1 + Level*( Wi/60 + Ma/120 )',
      potency: 'Level*2%',
    },
  },
  healing_prayer: {
    name: 'Healing Prayer',
    blurb: 'Restores some Hit Points to the target.',
    calc: {
      mpCost: '5 + Level*4',
      potency: '40 + Level*( Wi/2 )',
    },
  },
  holy_force: {
    name: 'Holy Force',
    blurb:
      'The gods intervene, knocking away nearby enemies and healing nearby allies.',
    calc: {
      mpCost: '40 + 2*( Level/3 )',
      healing: '20 + Level*( Wi/24 + Ma/60 )',
      potency: '1 + Level/10',
    },
  },
  iron_will: {
    name: 'Iron Will',
    blurb: 'PASSIVE: Increases Mental Resilience.',
    calc: {
      potency: 'Level*3%',
    },
  },
  mystic_focus: {
    name: 'Mystic Focus',
    blurb: 'Converts Action Points to Magic Power.',
    calc: {
      apCost: '100',
      potency: '50 + Level*( Wi/5 )',
    },
  },
  sanctuary: {
    name: 'Sanctuary',
    blurb:
      'Allies in the affected area recover some Hit Points each second for the duration.',
    calc: {
      range: '1 + Level/2',
      mpCost: '30 + Level*5',
      healing: 'Level*( Wi/10 )',
    },
  },
  send_mana: {
    name: 'Send Mana',
    blurb: 'Transfers some Magic Power to the target.',
    calc: {
      mpCost: 'Max MP * (30-Level)/100',
      potency: 'Max MP * (10+Level)/100',
    },
  },
};
