import * as PIXI from 'pixi.js';
import { BattleStore, PlayerStore } from '~/flux/stores';
import Colors from '~/constants/Colors';
import CanvasTools from '~/view/CanvasTools';

const MatchOverview = function() {
	PIXI.Container.call( this );

	this.dispose = () => {
		this.removeChildren();

		destroyTurnTimer();

		BattleStore.removeListener( BattleStore.BATTLE_EVENT, updateHealthTotals );
		BattleStore.removeListener( BattleStore.TURN_TIMER_INIT, initTurnTimer );
	};

	var versus = PIXI.Sprite.from('versus.png');
	versus.x = -versus.width / 2;
	versus.y = DT_CANVAS_GLOBALS.spacing;
	this.addChild( versus );

	const myTeam = BattleStore.getAll().myTeam;

	var playerBanner = PIXI.Sprite.from('team_banner.png');
	playerBanner.tint = myTeam === 'white' ? Colors.WHITE_TEAM : Colors.BLACK_TEAM;
	var playerName = new PIXI.Text(PlayerStore.getAll().displayName, {
		fontFamily: 'Courier New',
		fontSize: 18,
		fontStyle: 'bold',
		fill: myTeam==='white'? 0x000000 : 0xffffff,
        dropShadow: true,
        dropShadowDistance: 1,
        dropShadowColor: myTeam==='white'? 0xaaaaaa : 0x000000
	});
	playerName.width = Math.min( playerName.width, playerBanner.width*0.9 );
	playerName.scale.y = playerName.scale.x;
	playerName.x = Math.round( playerBanner.width/2 - playerName.width/2 );
	playerName.y = Math.round( playerBanner.height/2 - playerName.height/2 );
	playerBanner.addChild( playerName );
	playerBanner.x = Math.round( -playerBanner.width * 1.3 );
	this.addChild( playerBanner );

	var opponentBanner = PIXI.Sprite.from('team_banner.png');
	opponentBanner.tint = myTeam === 'white' ? Colors.BLACK_TEAM : Colors.WHITE_TEAM;

  const opponent_team = myTeam === 'white'
    ? BattleStore.getAll().battleState.black_team
    : BattleStore.getAll().battleState.white_team;
	var opponentName = new PIXI.Text(opponent_team.player?.scriptData?.displayName ?? 'Anonymous', {
		fontFamily: 'Courier New',
		fontSize: 18,
		fontStyle: 'bold',
		fill: myTeam==='white'? 0xffffff : 0x000000,
      dropShadow: true,
      dropShadowDistance: 1,
      dropShadowColor: myTeam === 'white' ? 0x000000 : 0xaaaaaa
	});
	opponentName.width = Math.min( opponentName.width, opponentBanner.width*0.9 );
	opponentName.scale.y = opponentName.scale.x;
	opponentName.x = Math.round( opponentBanner.width/2 - opponentName.width/2 );
	opponentName.y = Math.round( opponentBanner.height/2 - opponentName.height/2 );
	opponentBanner.addChild( opponentName );
	opponentBanner.x = Math.round( opponentBanner.width * 0.3 );
	this.addChild( opponentBanner );

	var playerBackFill = new PIXI.Container();
	while( playerBackFill.width < playerBanner.width ) {
		var tile = PIXI.Sprite.from( 'large_meter_backfill.png' );
		tile.x = playerBackFill.width;
		playerBackFill.addChild( tile );
	}
	playerBackFill.x = playerBanner.x + playerBanner.width/2 - playerBackFill.width/2;
	playerBackFill.y = playerBanner.y + playerBanner.height + DT_CANVAS_GLOBALS.spacing/3;
	this.addChild( playerBackFill );
	var playerHPBar = new PIXI.Graphics();
	playerBackFill.addChild( playerHPBar );

	var opponentBackFill = new PIXI.Container();
	while( opponentBackFill.width < opponentBanner.width ) {
		var tile = PIXI.Sprite.from( 'large_meter_backfill.png' );
		tile.x = opponentBackFill.width;
		opponentBackFill.addChild( tile );
	}	
	opponentBackFill.x = opponentBanner.x + opponentBanner.width/2 - opponentBackFill.width/2;
	opponentBackFill.y = opponentBanner.y + opponentBanner.height + DT_CANVAS_GLOBALS.spacing/3;
	this.addChild( opponentBackFill );
	var opponentHPBar = new PIXI.Graphics();
	opponentBackFill.addChild( opponentHPBar );

	const updateHealthTotals = () => {
		var whiteHP=0, blackHP=0, whiteMaxHP=0, blackMaxHP=0;

		const allUnits = BattleStore.getAll().battleState.allUnits;
		for( var prop in allUnits ) {
			var unit = allUnits[ prop ];
			if( unit.team === 'white' ) {
				whiteMaxHP += unit.max_hp;
				whiteHP += unit.hp;
			} else if( unit.team === 'black' ) {
				blackMaxHP += unit.max_hp;
				blackHP += unit.hp;
			}
		}

		var playerHP = myTeam==='white'? whiteHP : blackHP;
		var playerMaxHP = myTeam==='white'? whiteMaxHP : blackMaxHP;
		var opponentHP = myTeam==='white'? blackHP : whiteHP;
		var opponentMaxHP = myTeam==='white'? blackMaxHP : whiteMaxHP;

		playerHPBar.clear();
		playerHPBar.beginFill( myTeam==='white' ? Colors.WHITE_TEAM : Colors.BLACK_TEAM );
		playerHPBar.drawRect( 0, 0, playerBackFill.width*(playerHP/playerMaxHP), playerBackFill.height );
		playerHPBar.endFill();

		opponentHPBar.clear();
		opponentHPBar.beginFill( myTeam==='white' ? Colors.BLACK_TEAM : Colors.WHITE_TEAM );
		opponentHPBar.drawRect( 0, 0, opponentBackFill.width*(opponentHP/opponentMaxHP), opponentBackFill.height );
		opponentHPBar.endFill();
	};

	var	_whiteMaxHP=0, _blackMaxHP=0; 
	const allUnits = BattleStore.getAll().battleState.allUnits;
	for( var prop in allUnits ) {
		var unit = allUnits[ prop ];
		if( unit.team === 'white' ) {
			_whiteMaxHP += unit.max_hp;
		} else if( unit.team === 'black' ) {
			_blackMaxHP += unit.max_hp;
		}
	}
	updateHealthTotals();
	BattleStore.on( BattleStore.BATTLE_EVENT, updateHealthTotals );

	var _turnTimer;
	var _turnStartedAt;
	var _turnTimerInterval;
	const destroyTurnTimer = () => {
		clearInterval( _turnTimerInterval );

		if( _turnTimer ) {
			this.removeChild( _turnTimer );
			_turnTimer = null;
		}
	};

	const initTurnTimer = ( data ) => {
		destroyTurnTimer();

		_turnTimer = new PIXI.Text( '60', {
			fontFamily: 'Courier New',
			fontSize: CanvasTools.dynamicFontSizeString(32),
			fontStyle: 'bold',
			fill: 0xffffff,
	        dropShadow: true,
	        dropShadowDistance: 3,
	        dropShadowColor: 0x000000
		});

		_turnStartedAt = new Date().getTime();
		_turnTimerInterval = setInterval( ()=>{
			_turnTimer.text = Math.max(
				0,
				Math.round( ( 60 - (new Date().getTime()-_turnStartedAt )/1000 ) ).toString()
			); 
		}, 1000 );

		if (data.playerId === PlayerStore.getAll().loggedInPlayerId) {
			_turnTimer.x = playerBanner.x - _turnTimer.width - DT_CANVAS_GLOBALS.spacing;
		} else {
			_turnTimer.x = opponentBanner.x + opponentBanner.width + DT_CANVAS_GLOBALS.spacing;
		}
		_turnTimer.y = versus.y + versus.height/2 - _turnTimer.height/2;
		this.addChild( _turnTimer );
	};
	BattleStore.on( BattleStore.TURN_TIMER_INIT, initTurnTimer );
}
MatchOverview.prototype = Object.create(PIXI.Container.prototype)
MatchOverview.prototype.constructor = MatchOverview
export default MatchOverview
