import CanvasTools from '~/view/CanvasTools';
import LightningEffect from '../../../effects/LightningEffect';
import Audio from '~/Audio';

const LightningRendering = {
  render: function (data, gameBoard, isCaverns) {
    if (data.isProc) {
      return;
    }

    const actorSprite = gameBoard.getAllPieceSprites()[data.actorId];
    const render_movement = !isCaverns || actorSprite.gamePiece.hero;
    const transitTime = render_movement ? actorSprite.puppet.getCooldown() : 0;

    render_movement &&
      actorSprite.puppet
        .getMovements()
        .magicProjectile.movePuppet({ transitTime });
  },

  renderResult: function (data, gameBoard) {
    console.log('\nlightning renderResult', data);
    if (typeof data.strikes === 'object') {
      data.strikes = Object.values(data.strikes);
    }

    for (var i = 0; i < data.strikes.length; ++i) {
      var actorSprite = gameBoard.getAllPieceSprites()[data.strikes[i].actorId];
      var victimSprite =
        gameBoard.getAllPieceSprites()[data.strikes[i].victimId];

      console.log({ actorSprite, victimSprite });
      if (!actorSprite || !victimSprite) {
        return;
      }

      var dist = CanvasTools.distanceFromSpriteToSprite(
        actorSprite,
        victimSprite
      );

      for (var j = 16; j < dist; j += 32) {
        var image = new LightningEffect();
        image.rotation = CanvasTools.angleFromSpriteToSprite(
          actorSprite,
          victimSprite
        );
        image.x = actorSprite.x + (victimSprite.x - actorSprite.x) * (j / dist);
        image.y =
          actorSprite.y + (victimSprite.y - actorSprite.y) * (j / dist) - 13;
        gameBoard.addChild(image);
      }

      // _engine.battleScreen.sfx.play( new UnitAbilitySoundEffects.LIGHTNING_02 );
      Audio.play('lightning');
      TweenMax.delayedCall(0.15, Audio.play, ['lightning']);
      TweenMax.delayedCall(0.4, Audio.play, ['lightning']);
    }
  },
};
export default LightningRendering;
