// import chain from 'lodash/chain'
// import includes from 'lodash/includes'

let PLATFORM = getUrlVars().platform || 'home';
if (window.steamContext && PLATFORM !== 'steam_purchase_redirect') {
  PLATFORM = 'steam';
}
if (process.env.YANDEX_BUILD === 'true') {
  PLATFORM = 'yandex';
}

export default {
  AUTO_LOGIN: true,
  DOM_IMG_PATH: `${process.env.ASSETS_PATH || 'assets'}/img/DOM`,
  LOCALE: getLocale(),
  PLATFORM,
  RECOVERY_ID: getUrlVars().recoveryId,
};

// Read a page's GET URL variables and return them as an associative array.
function getUrlVars() {
  var vars = [], hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++)
  {
    hash = hashes[i].split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}

function getLocale() {
  return 'en';

  // These window.navigator contain language information
  // 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"]) Firefox^32, Chrome^32
  // 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11, Safari,
  //                 Chrome sends Browser UI language
  // 3. browserLanguage -> UI Language of IE
  // 4. userLanguage    -> Language of Windows Regional Options
  // 5. systemLanguage  -> UI Language of Windows
  var browserLanguagePropertyKeys = ['languages', 'language', 'browserLanguage', 'userLanguage', 'systemLanguage'];

  var availableLanguages = ['en']; // ['pl','pt','es','en'];

  var detectedLocale = chain(window.navigator)
    .pick(browserLanguagePropertyKeys) //Get only language properties
    .values() //Get values of the properties
    .flatten() //flatten all arrays
    .compact() //Remove undefined values
    .map(function (x) {
      return x.substr(0, 2); //Shorten strings to use two chars (en-US -> en)
    })
    .find(function (x) {
      return includes(availableLanguages, x); //Returns first language matched in available languages
    })
    .value();

  return detectedLocale || 'en'; //If no locale is detected, fallback to 'en'
}
